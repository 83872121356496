import { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import qs from "qs";
import moment from "moment";
import BarcodeReader from "react-barcode-reader";
import { Close, Edit, Save } from "@mui/icons-material";
import FillStationTopLabelModal from "../../../components/fillStationTopLabelModal";
import FillStationPatientLabelModal from "../../../components/fillStationPatientLabelModal";
import { SimpleBackdropSpinner } from "../../../components/loaders";
import FillStationActions from "../../../redux/fill_process/fill_station/actions";
import WorkGroupSelectionActions from "../../../redux/fill_process/work_group_selection/actions";
import AlertModal from "../../../components/alertModal";
import { Link } from "react-router-dom";
import PrintPdfFillStation from "../../../components/printPdfFillStation1";
const { getProductBarcodeData } = require("../../../common/helperFunctions");

const {
  getFillStationDetailsById,
  getOtherDetails,
  updateOtherDetailsFill,
  printRxLabels,
  addOtherDetailsFill,
  clearSetPrintRxLabels,
  clearUpdatedDetails,
  clearAddedDetails,
  initFillStation,
  getRxDetailsById,
  clearRxDetailsById,
  clearFillStationDetailsById,
  clearInitFillStationData,
} = FillStationActions;

const { getWorkGroupData, clearSetWorkgroupsData } = WorkGroupSelectionActions;

const FillStation = () => {
  const fillStationDetailsById = useSelector(
    (state: any) => state?.fillStation?.fillStationDetailsById
  );
  const fillStationData: any = useSelector(
    (state: any) => state?.fillStation?.fillStationData
  );
  const otherDetails = useSelector(
    (state: any) => state?.fillStation?.otherDetails
  );
  const updatedDetailsFill = useSelector(
    (state: any) => state?.fillStation?.updatedDetailsFill
  );
  const printRxLabelsResponse = useSelector(
    (state: any) => state?.fillStation?.printRxLabelsResponse
  );
  const workgroupsData = useSelector(
    (state: any) => state?.workGroupSelection?.workgroupsData
  );
  const addedDetailsFill = useSelector(
    (state: any) => state?.fillStation?.addedDetailsFill
  );
  const rxDetailsById = useSelector(
    (state: any) => state?.fillStation?.rxDetailsById
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [labelPrintFlag, setLabelPrintFlag] = useState<boolean>(false);
  const [topLabelModalFlag, setTopLabelModalFlag] = useState<boolean>(false);
  const [patientLabelModalFlag, setPatientLabelModalFlag] =
    useState<boolean>(false);
  const [isAutomode, setIsAutoMode] = useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [editElementId, setEditElementId] = useState("");
  const [editedLot, setEditedLot] = useState("");
  const [editedExpiration, setEditedExpiration] = useState("");
  const [editedSerialNumber, setEditedSerialNumber] = useState("");
  const [topLabelRx, setTopLabelRx] = useState("");
  const [lpnNumber, setLpnNumber] = useState("");
  const [alertModalFlag, setAlertModalFlag] = useState<boolean>(false);
  const [alertModalTitle, setAlertModalTitle] = useState<string>("");
  const [alertModalMessage, setAlertModalMessage] = useState<string>("");
  const [callInit, setCallInit] = useState<boolean>(false);
  const [userDetailsState, setUserDetailsState] = useState<any>();
  const [isTopLabelScannedForManual, setIsTopLabelScannedForManual] =
    useState<boolean>(false);
  const [scanPending, setScanPending] = useState<boolean>(false);
  const [topLabelAccidentalScanned, setTopLabelAccidentalScanned] =
    useState("");

  const getOtherDetailsHandler = useCallback(() => {
    const payload = {
      fill_rx_id: fillStationData?.data?.metaData?.rx_id,
    };
    dispatch(getOtherDetails(qs.stringify(payload)));
  }, [fillStationData, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearSetPrintRxLabels());
      dispatch(clearRxDetailsById());
      dispatch(clearFillStationDetailsById());
      dispatch(clearInitFillStationData());
      dispatch(clearSetWorkgroupsData());
    };
  }, [dispatch]);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("user") || "");
    const payload = {
      user_id: userDetails?.id || "",
    };
    dispatch(getFillStationDetailsById(qs.stringify(payload)));
    setLoading(true);
    setUserDetailsState(userDetails);
  }, [dispatch]);

  useEffect(() => {
    if (fillStationDetailsById) {
      setLoading(false);
      if (!fillStationDetailsById?.success) {
        toast.error(fillStationDetailsById?.message);
        dispatch(clearFillStationDetailsById());
      } else if (fillStationDetailsById?.success) {
        if (fillStationDetailsById?.data?.response?.auto) {
          setIsAutoMode(true);
        } else if (!fillStationDetailsById?.data?.response?.auto) {
          setIsAutoMode(false);
        }
      }
    }
  }, [fillStationDetailsById]);

  const getWorkGroupDataHandler = useCallback(() => {
    dispatch(getWorkGroupData(fillStationData?.data?.metaData?.workgroup_id));
  }, [fillStationData, dispatch]);

  useEffect(() => {
    if (fillStationData) {
      setLoading(false);
      if (!fillStationData?.success) {
        toast.error(fillStationData?.message);
        //RESET LPN STATES HERE
      } else if (fillStationData?.success) {
        setIsTopLabelScannedForManual(false);
        setCallInit(true);
        getOtherDetailsHandler();
        getWorkGroupDataHandler();
      }
    }
  }, [
    fillStationData,
    dispatch,
    getOtherDetailsHandler,
    getWorkGroupDataHandler,
  ]);

  useEffect(() => {
    if (workgroupsData && workgroupsData?.success) {
      if (!scanPending) {
        if (callInit) {
          if (workgroupsData?.data?.[0]?.fill_assign_remaining >= 1) {
            const payload = {
              user_id: userDetailsState?.id || "",
            };
            dispatch(printRxLabels(qs.stringify(payload)));
          }
        }
        setCallInit(false);
      }
    }
  }, [workgroupsData]);

  useEffect(() => {
    let timer: any;
    if (fillStationData?.success) {
      timer = setInterval(getWorkGroupDataHandler, 20000);
    }
    return () => clearInterval(timer);
  }, [fillStationData, getWorkGroupDataHandler]);

  useEffect(() => {
    if (otherDetails) {
      setLoading(false);
      if (!otherDetails?.success) {
        toast.error(otherDetails?.message);
      } else if (otherDetails?.success) {
      }
    }
  }, [otherDetails]);

  useEffect(() => {
    if (printRxLabelsResponse) {
      setLoading(false);
      if (!printRxLabelsResponse?.success) {
        toast.error(printRxLabelsResponse?.message);
      } else if (printRxLabelsResponse?.success) {
        setLabelPrintFlag(true);
      }
    } else {
      setLabelPrintFlag(false);
    }
  }, [printRxLabelsResponse]);

  useEffect(() => {
    if (rxDetailsById) {
      setLoading(false);
      if (!rxDetailsById?.success) {
        toast.error(rxDetailsById?.message);
      } else if (rxDetailsById?.success) {
        setTopLabelModalFlag(true);
      }
    }
  }, [rxDetailsById]);

  useEffect(() => {
    if (updatedDetailsFill) {
      setLoading(false);
      dispatch(clearUpdatedDetails());
      if (!updatedDetailsFill?.success) {
        toast.error(updatedDetailsFill?.message);
      } else if (updatedDetailsFill?.success) {
        getOtherDetailsHandler();
        setEditIndex(-1);
        setEditElementId("");
        setEditedLot("");
        setEditedExpiration("");
        setEditedSerialNumber("");
      }
    }
  }, [updatedDetailsFill, getOtherDetailsHandler, dispatch]);

  useEffect(() => {
    if (addedDetailsFill) {
      setLoading(false);
      dispatch(clearAddedDetails());
      if (!addedDetailsFill?.success) {
        toast.error(addedDetailsFill?.message);
      } else if (addedDetailsFill?.success) {
        getOtherDetailsHandler();
      }
    }
  }, [addedDetailsFill, dispatch, getOtherDetailsHandler]);

  const printRxLabelsHandler = () => {
    const payload = {
      user_id: userDetailsState?.id || "",
    };
    dispatch(printRxLabels(qs.stringify(payload)));
    setLoading(true);
    setAlertModalTitle("Scan Top Label");
    setAlertModalMessage(
      "Please scan the printed Top label to proceed with Fill Station Process"
    );
    setAlertModalFlag(true);
  };

  const checkValidBarcode = (code: any) => {
    var pattern = new RegExp(/^[0-9]*$/);
    return pattern.test(code);
  };

  const onScanning = async (data: any, isError: boolean) => {
    if (!labelPrintFlag) {
      toast.error("Please click on start fill process");
      return;
    }
    setAlertModalFlag(false);
    console.log("Scanned Barcode", data, checkValidBarcode(data));

    if (!isAutomode) {
      let res = await fetchTopLabelDetailsForManual(data);
      if (res === 0) {
        if (isError) {
          //LPN is scanned
          if (checkValidBarcode(data)) {
            if (isTopLabelScannedForManual) {
              setLpnNumber(data);
              const payload = {
                rx_id: topLabelRx,
                is_manual_mode: isAutomode == false ? true : false,
                lpn: data,
                user_id: userDetailsState?.id || "",
              };
              dispatch(initFillStation(payload));
              setLoading(true);
            }
          } else {
            //LPN BARCODE INVALID
          }
        } else {
          //PRODUCT is scanned
          fetchProductLabelDetails(data);
        }
      }
    } else {
      let res = await fetchTopLabelDetails(data);
      res === 0 && fetchProductLabelDetails(data);
    }
  };

  const fetchTopLabelDetails = (data: any) => {
    console.log("Expected Qty: ", fillStationData?.data?.metaData?.quantity);
    console.log("Scanned: ", otherDetails?.data?.response.length);
    if (data?.toString()?.includes(";")) {
      if (otherDetails?.data?.response.length < fillStationData?.data?.metaData?.quantity) {
        console.log("Order not complete");
        toast.error("Order not complete");
        return 1;
      }
      if (topLabelAccidentalScanned == data.toString().split(";")[0]) {
        return 1;
      }
      setTopLabelAccidentalScanned(data.toString().split(";")[0]);
      setTopLabelRx(data.toString().split(";")[0]);
      const payload = {
        rx_id: data.toString().split(";")[0],
        is_manual_mode: isAutomode == false ? true : false,
        lpn: lpnNumber,
        user_id: userDetailsState?.id || "",
      };
      dispatch(initFillStation(payload));
      setLoading(true);
      return 1;
    }
    return 0;
  };

  const fetchTopLabelDetailsForManual = (data: any) => {
    if (data?.toString()?.includes(";")) {
      setAlertModalFlag(true);
      setAlertModalTitle("Scan Tote's LPN");
      setAlertModalMessage(
        "Scan the Tote's LPN# to proceed with the fill station process"
      );
      if (topLabelAccidentalScanned == data.toString().split(";")[0]) {
        return 1;
      }
      setTopLabelAccidentalScanned(data.toString().split(";")[0]);
      setIsTopLabelScannedForManual(true);
      setTopLabelRx(data.toString().split(";")[0]);
      return 1;
    }
    return 0;
  };

  const fetchProductLabelDetails = async (data: any) => {

    const serialized = fillStationData?.data?.metaData?.serialized;
    let { addExpiration, addLot, addSerialNumber } = await getProductBarcodeData(data, serialized);

    if (addSerialNumber) {
      const payload = {
        rx_number: fillStationData?.data?.metaData?.rx_id,
        lot: addLot || "",
        expiration: addExpiration
          ? moment.utc(addExpiration?.toLocaleString()).format("YYYY-MM-DD")
          : null,
        serial_number: addSerialNumber || "",
        gs1_label: data || "",
      };
      dispatch(addOtherDetailsFill(payload));
      setLoading(true);
      addLot = null;
      addExpiration = null;
      addSerialNumber = null;
    } else {
      toast.error("Serial number is mandatory");
    }
  };

  const updateMaterialDetailHandler = () => {
    if (editedSerialNumber === "") {
      toast.warn("Please enter the Serial Number");
      return;
    }
    const payload = {
      rx_dtl_id: editElementId,
      lot: editedLot,
      expiration: editedExpiration,
      serial_number: editedSerialNumber,
    };
    dispatch(updateOtherDetailsFill(payload));
    setLoading(true);
  };

  const editItemHandler = (index: number, status: boolean) => {
    if (!status) {
      setEditIndex(-1);
      setEditElementId("");
      setEditedLot("");
      setEditedExpiration("");
      setEditedSerialNumber("");
      return;
    }
    const arr = otherDetails?.data?.response;
    setEditIndex(index);
    setEditElementId(arr[index]?.rx_dtl_id);
    setEditedLot(arr[index]?.lot);
    setEditedExpiration(
      arr[index]?.expiration &&
      moment.utc(arr[index].expiration.toLocaleString()).format("YYYY-MM-DD")
    );
    setEditedSerialNumber(arr[index]?.serial_number);
  };

  const handleOnClickReprint = () => {
    if (!topLabelRx) {
      toast.error("Please scan the top label first");
      return;
    }
    const payload = {
      rx_number: topLabelRx,
      user_id: userDetailsState?.id || "",
      reprinting: 1,
    };
    dispatch(getRxDetailsById(qs.stringify(payload)));
    setLoading(true);
  };

  const handleOnClickScan = () => {
    setScanPending(true);
    setLabelPrintFlag(true);
    setAlertModalTitle("Scan Top Label");
    setAlertModalMessage(
      "Please scan the printed Top label to proceed with Fill Station Process"
    );
    setAlertModalFlag(true);
  };

  return (
    <section className="body-routing">
      <div className="page-wrap">
        <div className="breadcrumb-block mode-row">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/fill_process/fill_station">Fill Process</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Fill Station
              </li>
            </ol>
          </nav>
          <div className="mode-block text-center">
            <h4>Fill Station Mode</h4>
            <span>
              {fillStationDetailsById?.success
                ? isAutomode
                  ? "Auto"
                  : "Manual"
                : ""}
            </span>
          </div>
        </div>
        {labelPrintFlag ? (
          <div className="fill-station-wrap">
            <div className="static-info-card mr-b20">
              <div className="blue-card-panel">
                <div className="b-card-body pd10">
                  <div className="row">
                    <div className="col-sm">
                      <div className="static-info">
                        <h5>Work Group Name</h5>
                        <span>{workgroupsData?.data?.[0]?.name}</span>
                      </div>
                    </div>
                    <div className="col-sm">
                      <div className="static-info">
                        <h5>Total</h5>
                        <span>{workgroupsData?.data?.[0]?.quantity}</span>
                      </div>
                    </div>
                    <div className="col-sm">
                      <div className="static-info">
                        <h5>Remaining to Fill</h5>
                        <span>{workgroupsData?.data?.[0]?.fill_remaining}</span>
                      </div>
                    </div>
                    <div className="col-sm">
                      <div className="static-info">
                        <h5>Remaining to PV2</h5>
                        <span>{workgroupsData?.data?.[0]?.pv2_remaining}</span>
                      </div>
                    </div>
                    <div className="col-sm">
                      <div className="static-info">
                        <h5>Remaining to Pack</h5>
                        <span>{workgroupsData?.data?.[0]?.pack_remaining}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tote-card-wrap">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-4">
                  <div className="blue-card-panel">
                    <div className="b-card-head">
                      <h5>Placement Picture of the Tote</h5>
                    </div>
                    <div className="b-card-body pd20">
                      <div className="box1">
                        <div className="box1-left">
                          <div className="tote-card1">Top Label</div>
                          <div>
                            {fillStationData?.data?.metaData?.medguide
                              ? "Med Guide"
                              : null}
                          </div>
                          <div>
                            {fillStationData?.data?.metaData?.rx_item_bag_label
                              ? "Zip Lock"
                              : null}
                          </div>
                        </div>
                        <div className="box1-right">
                          <div>
                            {fillStationData?.data?.metaData?.quantity}{" "}
                            {/* {fillStationData?.data?.metaData?.name}{" "} */}
                            {fillStationData?.data?.metaData?.description}
                          </div>
                          <div className="mr-l20">
                            {fillStationData?.data?.metaData?.serialized
                              ? "SCAN 2D Barcode On Each Rx Item"
                              : "SCAN 2D Barcode On Manufacturer Pack"}
                          </div>
                          {fillStationData?.data?.metaData?.supply_items?.map(
                            (item: any) => {
                              return (
                                <div>
                                  {item?.quantity} {item?.product_id}
                                </div>
                              );
                            }
                          )}
                          {otherDetails &&
                            otherDetails?.success &&
                            otherDetails?.data?.response?.map((item: any) => {
                              return (
                                <div>
                                  {item?.quantity} {item?.product_description}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4">
                  <div className="blue-card-panel">
                    <div className="b-card-head">
                      <h5>Rx Info</h5>
                    </div>
                    <div className="b-card-body pd20">
                      <div>{`Rx Number: ${fillStationData?.data?.metaData?.rx_id || ''}`}</div>
                      <div>{`Patient Name: ${fillStationData?.data?.metaData?.rxPatient?.firstName || ''} ${fillStationData?.data?.metaData?.rxPatient?.lastName || ''}`}</div>
                      <div>{`MD Name: ${fillStationData?.data?.metaData?.rxDoctor?.name || ''}`}</div>
                      <div>{`Ship Date: ${moment(fillStationData?.data?.metaData?.rxShipDate?.slice(0,-1)).format("MM/DD/YYYY") || ''}`}</div>
                    </div>
                  </div>
                </div>
                {fillStationData?.data?.metaData?.product_instructions && (
                  <div className="col-sm-12">
                    <div className="note-text mr-b20">
                      <p>
                        {`Note: ${fillStationData.data.metaData.product_instructions}`}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="rfm-data">
              <div className="text-end mr-b10">
                <button
                  type="button"
                  className="blue-btn pd-l30 pd-r30"
                  data-bs-toggle="modal"
                  data-bs-target="#printSModal"
                  onClick={() => handleOnClickReprint()}
                >
                  Reprint Label
                </button>
              </div>
              <div className="blue-card-panel">
                <div className="b-card-head">
                  <h5>Remaining Fill Materials</h5>
                </div>
                <div className="b-card-body table-responsive pd10">
                  <TableContainer>
                    <Table className="table small-table cus-gray-table table-center table-bordered mr0">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Seq</TableCell>
                          <TableCell align="center">Fill Product No.</TableCell>
                          <TableCell align="center">
                            Fill Product Description
                          </TableCell>
                          <TableCell align="center">Quantity</TableCell>
                          <TableCell align="center">Lot</TableCell>
                          <TableCell align="center">Expiration</TableCell>
                          <TableCell align="center">Serial No.</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {otherDetails &&
                          otherDetails?.success &&
                          otherDetails?.data?.response?.map(
                            (item: any, index: number) => {
                              return (
                                <TableRow>
                                  <TableCell align="center">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.product_id}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.product_description}
                                  </TableCell>
                                  <TableCell className="text-end">
                                    {item.quantity}
                                  </TableCell>
                                  <TableCell align="center">
                                    {index === editIndex ? (
                                      <div className="t-input">
                                        <input
                                          maxLength={255}
                                          type="text"
                                          value={editedLot}
                                          name="lot"
                                          id=""
                                          onChange={(event) =>
                                            setEditedLot(event.target.value)
                                          }
                                        />
                                      </div>
                                    ) : (
                                      item.lot
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {index === editIndex ? (
                                      <div className="t-input">
                                        <input
                                          type="date"
                                          value={editedExpiration}
                                          name="expiration"
                                          id=""
                                          onChange={(event) =>
                                            setEditedExpiration(
                                              event.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    ) : (
                                      item.expiration &&
                                      moment(
                                        item.expiration?.slice(0, -1)
                                      ).format("MM/DD/YYYY")
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {index === editIndex ? (
                                      <div className="t-input">
                                        <input
                                          maxLength={255}
                                          type="text"
                                          value={editedSerialNumber}
                                          name="serialNumber"
                                          id=""
                                          onChange={(event) =>
                                            setEditedSerialNumber(
                                              event.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    ) : (
                                      item.serial_number
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {index === editIndex ? (
                                      <>
                                        <button
                                          className="action-btn"
                                          onClick={() =>
                                            editItemHandler(index, false)
                                          }
                                        >
                                          <Close fontSize="small" />
                                        </button>
                                        <button
                                          className="action-btn"
                                          onClick={() =>
                                            updateMaterialDetailHandler()
                                          }
                                        >
                                          <Save fontSize="small" />
                                        </button>
                                      </>
                                    ) : (
                                      <button
                                        className="action-btn"
                                        onClick={() => {
                                          editItemHandler(index, true);
                                        }}
                                      >
                                        <Edit fontSize="small" />
                                      </button>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                      </TableBody>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={3} className="text-start">
                            Total
                          </TableCell>
                          <TableCell
                            className="text-end cursor-pointer"
                            role="button"
                            data-bs-toggle="modal"
                            data-bs-target="#qAlertgModal"
                          >
                            {fillStationData?.data?.metaData?.quantity}
                          </TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
            <FillStationTopLabelModal
              open={topLabelModalFlag}
              closeModal={() => setTopLabelModalFlag(false)}
              openModal={() => setPatientLabelModalFlag(true)}
              printRxLabelsResponse={rxDetailsById}
            />
            <FillStationPatientLabelModal
              open={patientLabelModalFlag}
              closeModal={() => {
                setPatientLabelModalFlag(false);
                dispatch(clearRxDetailsById());
              }}
              printRxLabelsResponse={rxDetailsById}
            />
            <AlertModal
              title={alertModalTitle}
              message={alertModalMessage}
              open={alertModalFlag}
              closeModal={() => setAlertModalFlag(false)}
            />
            <PrintPdfFillStation
              printRxLabelsResponse={printRxLabelsResponse}
              labelType="both"
              printNow={false}
              setLabelPrintFlag={() => setLabelPrintFlag(false)}
            />
            <BarcodeReader
              onScan={(data: any) => {
                onScanning(data, false);
              }}
              onError={(error: any) => {
                console.log("barcode error it is", error);
                onScanning(error, true);
              }}
            />
          </div>
        ) : (
          <div>
            <div className="invite-card d-flex align-items-center justify-content-between">
              <h5>
                Please Click on “Start Fill Process” button to Print Top Label &
                associated RX Label of the Work Group
              </h5>
              <button
                type="button"
                className="blue-btn pd-l20 pd-r20"
                data-bs-toggle="modal"
                data-bs-target="#lpnAlertgModal"
                onClick={() => printRxLabelsHandler()}
              >
                Start Fill Process
              </button>
            </div>
            <div className="invite-card d-flex align-items-center justify-content-between">
              <h5>
                Please Click on “Scan” button to scan re-printed Top Label &
                associated RX Label of the Work Group
              </h5>
              <button
                type="button"
                className="blue-btn pd-l50 pd-r50"
                data-bs-toggle="modal"
                data-bs-target="#lpnAlertgModal"
                onClick={() => handleOnClickScan()}
              >
                Scan
              </button>
            </div>
          </div>
        )}
      </div>
      <SimpleBackdropSpinner open={loading} />
    </section>
  );
};

export default FillStation;