import GS1DigitalLinkToolkit from "../lib/GS1DigitalLinkToolkit";

export const getProductBarcodeData = (data: any, serialized: boolean) => {

  const gs1dlt = new GS1DigitalLinkToolkit();
  console.log("barcode data", data);

  let addGtin = null;
  let addLot = null;
  let addExpiration = null;
  let addSerialNumber = null;

  try {
    const parsed = gs1dlt.extractFromGS1elementStrings(data);
    console.log(JSON.stringify(parsed, null, 2));

    for (const [key, value] of Object.entries(parsed)) {
      switch(key) {
        // 01 = GTIN
        case '01':
          console.log(`GTIN = ${value}`);
          addGtin = value;
          break;
        // 10 = LOT
        case '10':
          console.log(`LOT = ${value}`);
          addLot = value;
          break;
        // 17 = Expiry
        case '17':
          console.log(`EXPIRY = ${value}`);
          let year = new Date().getFullYear().toString().slice(0, 2) + value.slice(0, 2);
          let month = value.slice(2, 4);
          let day = value.slice(4, 6);
          addExpiration = year + "-" + month + "-" + day;
          break;
        // 21 = Serial Number
        case '21':
          console.log(`Serial Number = ${value}`);
          addSerialNumber = value;
          break;
        default:
          console.log(`Unkown Tag Field -> ${key} : ${value}`);
      }
    }
  } catch (e){
    console.log(`Error parsing BarCode [${data}]`, e);
  }

  // no serial number, but not serialized product, return GTIN as serial number
  if(addSerialNumber === null && !serialized) {
    addSerialNumber = addGtin;
  }

  return {
    addSerialNumber,
    addExpiration,
    addLot
  };
};